import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import modules from "./modules";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  /* 获取派生值 */
  getters: {
    visitedViews: (state) => state.tagsView.visitedViews,
    cachedViews: (state) => state.tagsView.cachedViews,
  },
  /* 同步修改 */
  mutations: {
    changeIsCollapse(state, data) {
      state.isCollapse = data;
    },
  },
  /* 异步获取 */
  actions: {},
  /* 模块拆分 */
  modules,
});
